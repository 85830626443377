<template>
	<section class="SigninSection">
		<div class="Container">
			<div class="Box">
				<a href="#/home/carrello"><img src="images/BackButtonCircle.png" style="width:50px;"/></a>
			</div>
			<div class="Box">
				<h1>Procedi al pagamento</h1>
				<div class="TxtEditor">
					<h2>Il totale ordine è : {{toEUR(totCarrello)}}</h2>
					<p>Ti ricordiamo che questa vendita è regolata dai nostri <a href="/#/pagine/termini-e-condizioni" target="_blank"> termini e condizioni di vendita.</a></p>
					<p>L'ordine sarà elaborato ed evaso in 30 giorni lavorativi, <b>salvo ritardi da parte del fornitore</b></p>
				</div>
				<br><br>
				<div v-if="this.OkOrdine" class="Button Big CustomColor2">
					<a href="javascript:void(0);" @click="submit">Procedi con il pagamento </a>
				</div>
				<div v-else class="Button Big CustomColor2">
					<a href="javascript:void(0);">Procedi con il pagamento </a>
				</div>
				<form id="payform" method='POST' :action="PaymentData.requestUrl" enctype="application/x-www-form-urlencoded">
					<input v-for="( Valore, Nome ) in PaymentData.requestParams"
						:key="Nome" 
						type='hidden' 
						:name="Nome"
						:value="Valore" />
					<input id="descrizione" key="descrizione" type='hidden' name="descrizione" value="aa" />
				</form>
			</div>
		</div>
	</section>
</template>

<script>
import {priceMixin, valutaMixin, risorseMixin} from "@/mixin.js";
import OrderService from "@/services/order.service.js";
import OrderData from "@/models/OrderData.model.js";
export default {
	name: "ToPayment",
    data(){
        return {
            PaymentData:[],
			IdOrdine: null,
			OkOrdine: false
		}
	},
    mixins: [priceMixin, valutaMixin, risorseMixin],
	computed:{
		totCarrello: function(){
            return  this.$store.getters.getTotaleCarrello;
		}
    },
	methods: {
		PreparePayload: function(){
			let NoteOrdine = (this.$store.getters.getNoteOrdine ?? " ");
			let ds = this.$store.getters.getDatiSpedizione;

			if(ds != null && ds != ""){
				ds = JSON.parse(this.$store.getters.getDatiSpedizione);
			}
			let df = null;
			if(this.$store.getters.getFatturazione != null && this.$store.getters.getFatturazione == 2){
				df = this.$store.getters.getDatiFatturazione;
				if(df != null && df != ""){
					df = JSON.parse(this.$store.getters.getDatiFatturazione);
					if(df.FATT_COGNOME != null && df.FATT_COGNOME == ""){
						df.FATT_COGNOME = null;
					}
					if(df.FATT_NOME != null && df.FATT_NOME == ""){
						df.FATT_NOME = null;
					}
					if(df.FATT_PEC != null && df.FATT_PEC == ""){
						df.FATT_PEC = null;
					}
					if(df.FATT_CODSDI != null && df.FATT_CODSDI == ""){
						df.FATT_CODSDI = null;
					}
				}
			}
			return new OrderData(
				NoteOrdine,
				this.$store.getters.getTestata.IdCarrello,
				this.$store.getters.getK2AnagenIndir,
				this.$store.getters.getFatturazione,
				ds,
				df ,			
				this.$store.getters.getCostoSpedizione,
				this.PaymentData.requestParams.codTrans
			);
		},
		submit : function(){
			this.OkOrdine = false;
			let orderData = this.PreparePayload();
			OrderService.PaymentIntent(this.$store.getters.getToken, this.$store.getters.getShop, orderData).then(
				(data) =>{
					document.getElementById("descrizione").value = data.data.Data.IdOrdine;
					this.IdOrdine = data.data.Data.IdOrdine;
					document.getElementById("payform").submit();
				}
			);
		},
		recuperaPagamento : function(){
			this.$store.dispatch({
				type: 'getPagamentiDigitali',
				metodoPagamento: 'nexi',
				codShop: this.$store.getters.getShop,
				importo: this.$store.getters.getTotaleCarrelloCent
			})
			.then(
				(data) => {
					this.PaymentData = data;
					this.OkOrdine = true;
				}
			);
		},
	},
	mounted: function(){
        this.recuperaPagamento();
    }
}
</script>
<style scoped>
form {
	visibility: hidden;
}
</style>
